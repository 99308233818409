<template>
  <v-system-bar class="system-bar"
      style="border-top: 1px solid #e1dfdb; border-bottom: 1px solid #e1dfdb; background-color: #f7f5f3"
      app height="60">
        <img @click="goToHome" height="38px" style="cursor: pointer; float: left; padding-left: 5px; padding-top: 0px;" :src="static_path + '/box21_logo.svg'">
        <span class="version" style="color: #46423a; padding-top: 22px; min-width: 110px; padding-left: 10px; float: left; font-size: 0.9em;">
           0.98.07
        </span>
        <span class="project-description" style="color: #46423a; padding-top: 12px; padding-left: 0px; float: left; font-size: 1.5em;">
             {{selected_project}}
        </span>

        <v-spacer></v-spacer>
          <LocaleSwitcher/>
          <v-btn
                  v-if="user_authenticated"
                  class="ma-2"
                  text
                  icon
                  @click.native="goToMyProfile"
                  color="blue lighten-2"
          >
              <v-icon v-if="!decoded_avatar_properties">fas fa-user</v-icon>
              <gravatar v-else style="width: 40px;" :selected_properties="decoded_avatar_properties"></gravatar>
          </v-btn>
          <v-btn
            v-if="user_authenticated"
            class="ma-2"
            text
            icon
            @click.native="goToMyProfile('upgrade-subscription')"
            color="blue lighten-2"
          > 
              <v-icon v-if="subscriptionIcon" large color="primary">{{ subscriptionIcon }}</v-icon>
          </v-btn>

          <v-btn
              v-if="user_authenticated"
            class="ma-2"
            text
            icon
            @click.native="sign_out"
            color="blue lighten-2"
          >
            <v-icon>fas fa-sign-out-alt</v-icon>
          </v-btn>
          <v-btn
              v-else
              class="ma-2"
              text
              icon
              @click.native="goToLogin"
              color="black"
          >
            <v-icon>fas fa-sign-in-alt</v-icon>
          </v-btn>
    </v-system-bar>
</template>

<script>
import LoadProjectsMixin from "@/mixins/LoadProjectsMixin";
import CurrentUserMixin from "@/mixins/CurrentUserMixin";
import Gravatar from "@/components/users/Gravatar";
import SnackbarsMixin from "@/mixins/SnackbarsMixin.vue";
import LocaleSwitcher from "@/components/LocaleSwitcher";


export default {
  mixins: [LoadProjectsMixin, CurrentUserMixin, SnackbarsMixin],
  name: "MenuBar",
  components: { Gravatar, LocaleSwitcher },
  data() {
    return {
    };
  },
  methods: {
    sign_out() {
      localStorage.token = false;
      this.$store.commit("user/clear_users");
      this.$store.commit("user/set_current_project_id", false);
      if (this.$route.path !== '/login') {
        this.$router.push("/login");
      }
    },
    goToMyProfile(source) {
      if (source === 'upgrade-subscription') {
        if (this.$route.path !== '/profile/upgrade-subscription') {
          this.$router.push({ path: '/profile/upgrade-subscription' });
        }
      } else {
        if (this.$route.path !== '/myprofile') {
          this.$router.push("/myprofile");
        }
      }
    },
    goToLogin() {
      if (this.$route.path !== '/login') {
        this.$router.push("/login");
      }
    },
    goToHome() {
      if (this.$route.path !== '/projects') {
        this.$router.push("/projects");
      }
    }
  },
  computed: {
    subscriptionIcon() {
      if (this.on_unauthenticated_pages) {
        return;
      }
      if (this.user_sub_id === null) {
        return "fas fa-spinner fa-spin";
      }

      const subscriptionId = Number(this.user_sub_id);

      switch (subscriptionId) {
        case 0:
          return "fas fa-seedling";
        case 1:
          return "fas fa-tree";
        case 2:
          return "fa-solid fa-mountain";
        case 3:
          return "fa-solid fa-earth-europe";
        default:
          return "fas fa-seedling";
      }
    },
    decoded_avatar_properties() {
      return this.current_user.avatar_properties;
    },
    user_authenticated() {
      return this.current_user !== false;
    },
    static_path() {
      return this.$api_url;
    },
    selected_project() {
      let project_id = this.$store.getters["user/get_current_project_id"];
      let selected = this.projects.filter((project) => {
        return project.id === parseInt(project_id);
      });

      if (selected.length === 0) {
        return "";
      } else {
        return selected[0].name;
      }
    },
  },
};
</script>

<style scoped>
@media (max-width: 600px) {
  img {
    height: 30px;
  }

  .version {
    display: none;
  }

  .project-description {
    display: none; 
  }
}
</style>