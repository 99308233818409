<template>
  <div>
    <row>
      <col6>
        {{$t('set_label_groups.current_annotations')}}
      </col6>
      <col6>
        <v-btn v-if="show_label_preview" small color="primary" @click.native="show_label_preview=false">
          <v-icon x-small>
            fas fa-eye
          </v-icon>
        </v-btn>
        <v-btn v-else small color="primary" @click.native="show_label_preview=true">
          <v-icon x-small>
            fas fa-eye-slash
          </v-icon>
        </v-btn>
      </col6>
    </row>
    <row v-if="!show_label_preview">
      <col12>
        <v-chip
                :dark="annotation.selected"
                :style="{
                'font-weight': annotation.selected ? 'bold': '',
                'background-color': 'rgba('+ annotation.color_values[0] + ',' + annotation.color_values[1] + ',' + annotation.color_values[2] + ')'}"
                style="float: left;" 
                v-for="annotation in filtered_annotations" :key="annotation.id"
                @click="toggleSelect(annotation)">
          {{ annotation.label }} - {{ annotation.conf }}
                <i class="fas fa-table-cells-large small ml-2"></i>
        </v-chip>
      </col12>
    </row>
    <row v-else>
      <col12>
        <row v-for="annotation in filtered_annotations" :key="annotation.id">

          <col6 style="padding-top: 0px; padding-bottom: 2px;">
            <v-chip
                :dark="annotation.selected"
                :style="{
                'font-weight': annotation.selected ? 'bold': '',
                'background-color': 'rgba('+ annotation.color_values[0] + ',' + annotation.color_values[1] + ',' + annotation.color_values[2] + ')'}"
                style="float: left;" 
                @click="toggleSelect(annotation)">
          {{ annotation.label }} - {{ annotation.conf }}
                <i class="fas fa-table-cells-large small ml-2"></i>
            </v-chip>
          </col6>
          <col6>
            <div v-if="labelid2_crop[annotation.label]">
              <img :src="'data:image/png;base64, ' + labelid2_crop[annotation.label]" style="max-width: 100%; max-height: 100px;"/>
            </div>
          </col6>
        </row>
        
      </col12>
    </row>
    <row>
      <col12>
        <annotate-labels v-if="selected" :type="selected.type" :placeholder="'Change label'"
                         @add_annotation="updateAnnotation"></annotate-labels>
      </col12>
    </row>

    <v-btn rounded style="margin-right: 5px; " small color="primary" id="clear_button_05" @click="clearAnnotations(0.3)">{{$t('set_label_groups.clear_annotations')}}</v-btn>
    <v-btn rounded small color="primary" id="clear_button" @click="clearAnnotations">{{$t('set_label_groups.clear_all')}}</v-btn>

    <row>
      <col12>
        <v-row>
          <v-card-text>
            <v-text-field id="filter_annotations"
                          v-model="filter_annotations" :label="$t('set_label_groups.filter_annotations')"></v-text-field>
          </v-card-text>
        </v-row>
      </col12>
    </row>
  </div>
</template>

<script>
  import AnnotateLabels from "@/components/AnnotateLabels";
  import Col12 from "@/components/layout/Col12";
  import Col6 from "@/components/layout/Col6";
  import Row from "@/components/layout/Row";
  import LoadLabelsMixin from "@/mixins/LoadLabelsMixin";
  // import MyBoxesModal from "@/components/MyBoxesModal";

  export default {
    name: "AnnotationsList",
    mixins: [LoadLabelsMixin],
    data () {
      return {
        'filter_annotations': '',
        'show_boxes_modal': false,
        'boxes_modal_label_name': false,
        'show_label_preview': false,
        'labelid2_crop': {},
      }
    },
    components: {Row, Col12, Col6, AnnotateLabels},
    props: {
      'annotations': {
        required: true
      },
      'suggestions': {
        required: false
      }
    },
    watch: {
      'annotations': function (newVal, oldVal) {
        if (newVal.length == oldVal.length) {
          return;
        }
        let this2 = this
        for(let annotation of this.annotations) {
          if(!this.labelid2_crop[annotation.label]) {
            this.$api.label.get_crop(annotation.label).then(response => {
              this2.$set(this2.labelid2_crop, annotation.label, response.crop)
            })
          }
        }
      }
    },
    computed: {
      'selected'() {
        let selected = this.annotations.filter(a => {
          return a.selected
        })
        if (selected.length > 0) {
          return selected[0]
        } else return false;
      },
      filtered_annotations () {
        if(this.filter_annotations === '') {
          return this.annotations
        }
        return this.annotations.filter(annotation=>{return annotation.label.toLowerCase().includes(this.filter_annotations.toLowerCase())})
      },
    },
    methods: {
      preview_label_in_modal(label_name) {
        this.$set(this, 'show_boxes_modal', true);
        this.$set(this, 'boxes_modal_label_name', label_name);
      },
      close_preview_label_in_modal() {
        this.$set(this, 'show_boxes_modal', false);
        this.$set(this, 'boxes_modal_label_name', null);
      },
      updateAnnotation(annotation) {
        this.$emit('update_label', this.selected.id, annotation.label);
      },
      clearAnnotations(less_than) {
        this.$emit('clear', less_than)
      },
      clearAnnotationsLessThan(value) {
        this.$emit('clear_less_than', value)
      },
      toggleSelect(annotation) {
        if (annotation.selected) {
          this.$emit('unselect', annotation.id);
        } else {
          this.$emit('select', annotation.id);
        }
      }
    }
  }
</script>

<style scoped>

</style>